/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@include mat.core();

@import "styles/variables";
@import "../node_modules/bootstrap/scss/bootstrap-reboot.scss";
@import "../node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "../node_modules/bootstrap/scss/bootstrap-utilities.scss";
@import "styles/reset";



$theme: mat.define-light-theme((
 color: (
   primary: $primary-palette,
   accent: $accent-palette,
   warn: $warn-palette
 ),
 typography: mat.define-typography-config(),
 density: 0,
));

@include mat.all-component-themes($theme);

.theme-text-primary{
  color: mat-color($primary-palette);
}
.theme-bg-primary{
  background: mat-color($primary-palette);
}
.theme-text-accent{
  color: mat-color($accent-palette);
}
.theme-bg-accent{
  background: mat-color($accent-palette);
}
.theme-text-warn{
  color: mat-color($warn-palette);
}
.theme-bg-warn{
  background: mat-color($warn-palette);
}

.mat-mdc-card {
  box-shadow: 0 3px 11px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px #9a9a9a1a;
}
.theme-shadow {
  box-shadow: 0 3px 11px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px #9a9a9a1a;
}
.mat-mdc-dialog-actions.mdc-dialog__actions {
    // justify-content: center;
}
mat-form-field.mat-mdc-form-field {
  display: block;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.edit-dialog {
    min-width: 340px;
}
.wide-edit-dialog {
  min-width: 640px;
}

.spacer {
  flex: 1 1 auto;
}
.form-block {
  padding: 16px;
  background-color: whitesmoke;
}
.form-block-list {
  // font-size: 13px;
  a {
    text-decoration: none;
    cursor: pointer;
  }
  mat-icon{
    &[color="warn"]{
      &:hover{
        color: #ff6b61;
      }
    }
    &[color="primary"]{
      &:hover{
        color: #42c0b3;
      }
    }
  }
}
.icon-button {
  cursor: pointer;
  vertical-align: middle;
  &.primary {
    color: #009688;
  }
  &.warn {
    color: #f44336;
  }
  &:hover {
    filter: brightness(1.2)
  }
}

.input-button-wrapper{
  display: flex;
  mat-form-field.mat-mdc-form-field {
    flex: 1 0 0%;
    display: inline-flex;
  }
  button{
    flex: 0 0 auto;
    height: 56px;
    border:none;
    border-bottom: 1px solid #898989;
    width: 48px;
    background-color: whitesmoke;
       
    mat-icon {
      position: relative;
      top: 5px;
    }
    &.long {
      width: 70px;
    }
  }
  button:hover{
      border-color:#111111;
      background-color: #ededed;
    
  }

}
.hover:hover {
  background-color: #ededed;
}
.background-whitesmoke{
  background-color: whitesmoke;
}
.transparent-input {
    background: transparent;
    border: none;
    width: 100%;
}
.fw-bold {
  font-weight: 500!important;
}
.fw-bolder {
  font-weight: 700!important;
}
.small {
  font-size: 14px;
}
.text-muted {
  color: #889eb3!important;
}